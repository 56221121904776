import styled from 'styled-components';
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Confetti from 'react-confetti';

const Wrapper = styled.div`
  background: url(${(props) => props.backgroundImg});
  height: 100vh;
  width: 100vw;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  transition: all 0.5s ease-in-out;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 5vh;
  left: 20vw;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  width: 20vw;
  transform: scale(2);
`;

const Button = styled.button`
  align-items: center;
  background-color: #0276ff;
  border-radius: 8px;
  border-style: none;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding: 10px 30px;
  font-size: 14px;
  margin: 10px auto 0;

  :hover {
    transform: scale(1.2);
  }
`;

function Gift({ backgroundImg }) {
  const [runConfetti, setRunConfetti] = useState(false);
  const [background, setBackground] = useState(null);

  const handleNextClick = () => {
    setRunConfetti(true);
    setTimeout(() => {
      setBackground(backgroundImg);
    }, 8000);
  };

  return (
    <Wrapper backgroundImg={background}>
      {!runConfetti && (
        <FormWrapper>
          <Button onClick={handleNextClick}>Разблокировать подарок</Button>
        </FormWrapper>
      )}
      <ToastContainer autoClose={3000} />
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        run={runConfetti}
      />
    </Wrapper>
  );
}

export default Gift;
