import styled from 'styled-components';
import { useState } from 'react';
import Question from './components/Question';
import Gift from './components/Gift';

const StartButton = styled.button`
  position: absolute;
  right: 30vw;
  bottom: 20vh;
  align-items: center;
  background-color: #0276ff;
  border-radius: 8px;
  border-style: none;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding: 10px 30px;
  font-size: 40px;

  :hover {
    transform: scale(1.2);
  }
`;

const data = [
  {
    id: 1,
    // answers: ['Мирославовна', 'Ивановна', 'Юриевна', 'Андреевна'],
    // correctAnswer: 'Мирославовна',
    success: 'Ох уж это стекло',
    // error: 'Отожмись.... 30 раз',
  },
  {
    id: 2,
    answers: ['06.01.2019', '20.12.2018', '05.04.2019', '05.06.2019'],
    correctAnswer: '06.01.2019',
    success: 'ПРОСТО МАШИНА',
    error: 'Спустись на первый этаж по ступенькам и поднимись назад',
  },
  {
    id: 3,
    answers: ['Есть такое'],
    correctAnswer: 'Есть такое',
    success: 'Есть такое',
    error: 'Отожмись 20 раз',
  },
  {
    id: 4,
    answers: [
      'Спросит будут ли там девушки',
      'Спросит с кем идет',
      'Разрешит',
      'Скажет: «Наконец-то ты куда-то уходишь»',
    ],
    correctAnswer: 'Скажет: «Наконец-то ты куда-то уходишь»',
    success: 'Надо чаще с дома выходить!',
    error: 'Ну камон. Станцуй свой лучший танец под барби герл!',
  },
  {
    id: 5,
    answers: [
      'Настя успокоится и придет мириться, но не признает вину',
      'Настя признает вину и извинится',
      'Настя все перекрутит, что виноват окажется Артем и он пойдет извиняться',
    ],
    correctAnswer:
      'Настя все перекрутит, что виноват окажется Артем и он пойдет извиняться',
    success: 'Как у них это постоянно выходит???',
    error: 'Ага, размечтался. Проснись проведи льдом по всему лицу!',
  },
  {
    id: 6,
    answers: [
      'в Киеве, возле метро',
      'в Хмельницком на кальяне',
      'в Хмельницком в кафе. Тогда познакомился и с Катей тоже',
    ],
    correctAnswer: 'в Хмельницком на кальяне',
    success: 'Офигеть? Помнишь. Походу кальян был слабый',
    error: 'А жаль. Придется отжаться с Евой на спине 10 раз',
  },
  {
    id: 7,
    answers: ['Да', 'Конечно', 'Не, у меня конференция будет'],
    correctAnswer: 'Конечно',
    success: 'Ну еще бы!',
    error: 'А ты рисковый парень. Ну тогда стань на руки у стены.',
  },
  {
    id: 8,
    answers: [
      'Papaj Gym',
      'Aligatores',
      'Papa GYM',
      'Pajaj Gym',
      'Total fitness',
      'Zdrofit',
    ],
    correctAnswer: 'Zdrofit',
    success: 'СНОВА МАШИНА!!!',
    error:
      'Походу слабо позанимались, ну давай тогда становую Настей на 15 раз',
  },
  {
    id: 9,
    answers: [
      'В корейском ресторане',
      'В Кебаб Кинге',
      'В тыпике',
      'Попался в инсте',
    ],
    correctAnswer: 'В Кебаб Кинге',
    success: 'Молодец!',
    error: 'А я помню! Время присесть 50 раз!',
  },
  {
    id: 10,
    // answers: [
    //   'В корейском ресторане',
    //   'В Кебаб Кинге',
    //   'В тыпике',
    //   'Попался в инсте',
    // ],
    // correctAnswer: 'В Кебаб Кинге',
    success: 'Полетаем?',
    // error: 'А я помню! Время присесть 50 раз!',
  },
];

function App() {
  const [step, setStep] = useState(0);

  return (
    <div>
      {step === 0 && (
        <div>
          <Question backgroundImg="/assets/start.JPG" isStart />
          <StartButton onClick={() => setStep(1)}>Start V2</StartButton>
        </div>
      )}
      {step === 1 && (
        <div>
          <Question
            question={data[0]}
            backgroundImg="/assets/question-1.JPG"
            onNext={() => setStep(data[0].id + 1)}
            input
          />
        </div>
      )}
      {step === 2 && (
        <div>
          <Question
            question={data[1]}
            backgroundImg="/assets/question-2.JPG"
            onNext={() => setStep(data[1].id + 1)}
          />
        </div>
      )}
      {step === 3 && (
        <div>
          <Question
            question={data[2]}
            backgroundImg="/assets/question-3.JPG"
            onNext={() => setStep(data[2].id + 1)}
          />
        </div>
      )}
      {step === 4 && (
        <div>
          <Question
            question={data[3]}
            backgroundImg="/assets/question-4.JPG"
            onNext={() => setStep(data[3].id + 1)}
          />
        </div>
      )}
      {step === 5 && (
        <div>
          <Question
            question={data[4]}
            backgroundImg="/assets/question-5.JPG"
            onNext={() => setStep(data[4].id + 1)}
          />
        </div>
      )}
      {step === 6 && (
        <div>
          <Question
            question={data[5]}
            backgroundImg="/assets/question-6.JPG"
            onNext={() => setStep(data[5].id + 1)}
          />
        </div>
      )}
      {step === 7 && (
        <div>
          <Question
            question={data[6]}
            backgroundImg="/assets/question-7.JPG"
            onNext={() => setStep(data[6].id + 1)}
          />
        </div>
      )}
      {step === 8 && (
        <div>
          <Question
            question={data[7]}
            backgroundImg="/assets/question-8.JPG"
            onNext={() => setStep(data[7].id + 1)}
          />
        </div>
      )}
      {step === 9 && (
        <div>
          <Question
            question={data[8]}
            backgroundImg="/assets/question-9.JPG"
            onNext={() => setStep(data[8].id + 1)}
          />
        </div>
      )}
      {step === 10 && (
        <div>
          <Question
            question={data[8]}
            backgroundImg="/assets/question-10.JPG"
            onNext={() => setStep(11)}
            last
            input
          />
        </div>
      )}
      {step === 11 && (
        <div>
          <Gift backgroundImg="/assets/gift.JPG" />
        </div>
      )}
    </div>
  );
}

export default App;
