import styled from 'styled-components';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Wrapper = styled.div`
  background: url(${(props) => props.backgroundImg});
  height: 100vh;
  width: 100vw;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 10vh;
  left: ${(props) => (props.rightSide ? '50vw' : '10vw')};
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 35vw;
  transform: scale(1.5);
`;

const RadioQuestion = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: #2196f3;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
`;

const Button = styled.button`
  align-items: center;
  background-color: #0276ff;
  border-radius: 8px;
  border-style: none;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding: 10px 30px;
  font-size: 14px;
  margin: 10px auto 0;

  :hover {
    transform: scale(1.2);
  }
`;

function Question({
  backgroundImg,
  onNext,
  isStart,
  question,
  last,
  input,
  rightSide,
}) {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [value, setValue] = useState('');

  const handleNextClick = () => {
    if (selectedAnswer === question?.correctAnswer || last || input) {
      toast(
        question?.success ?? `Давай сделаем поездку в ${value} очень крутой!`,
        { style: { fontSize: '40px' } }
      );
      setTimeout(() => onNext(), 3000);
    } else {
      toast.error(question.error, {
        style: { fontSize: '40px' },
      });
    }
  };

  return (
    <Wrapper backgroundImg={backgroundImg}>
      {!isStart && (
        <FormWrapper rightSide={rightSide}>
          {input ? (
            <input
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          ) : (
            question.answers.map((answer) => (
              <RadioQuestion key={answer.id}>
                {answer}
                <input
                  type="radio"
                  checked={selectedAnswer === answer}
                  onChange={() => setSelectedAnswer(answer)}
                  name="radio"
                />
                <span class="checkmark"></span>
              </RadioQuestion>
            ))
          )}
          <Button onClick={handleNextClick}>Next</Button>
        </FormWrapper>
      )}
      <ToastContainer autoClose={4000} />
    </Wrapper>
  );
}

export default Question;
